























































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModuleTabs extends Vue {
  private disableTooltip: boolean = true // 有省略号显示 tooltip,没有省略号不显示
  private tabs = [
    // { name: 'convertFastq', img: require('@/assets/index/expressionMatrix.png'), route: 'ExpressionMatrix', disable: false },
    { name: 'downstreamAnalysis', img: require('@/assets/index/downstreamAnalysis.png'), route: 'PreProcessing', disable: false },
    // { name: 'molecularSubtyping', img: require('@/assets/index/molecularSubtyping.png'), route: 'MolecularSubtyping', disable: false },
    // { name: 'VDJMiner', img: require('@/assets/index/knowledgeGraph.png'), route: 'VDJMiner', disable: false },
    // { name: 'spatialTranscriptomics', img: require('@/assets/index/spatialTranscriptomics.png'), route: 'SpatialOmics', disable: false }
  ]

  private getRoute (tab: any) {
    if (['molecularSubtyping', 'VDJMiner', 'spatialTranscriptomics'].includes(tab.name)) {
      return `/${tab.route}`
    } else return `/home/${this.$route.params.lang}/${tab.route}`
  }

  private Mouseenter (event: MouseEvent) {
    const et = event.target as HTMLInputElement
    if (!et) return
    this.disableTooltip = et.clientHeight >= et.scrollHeight
  }
}
