















import { Component, Vue } from 'vue-property-decorator'
import Banner from '@/components/index/Banner.vue'
import ModuleTabs from '@/components/index/ModuleTabs.vue'
import BottomEntry from '@/components/index/BottomEntry.vue'

@Component({
  components: {
    Banner,
    ModuleTabs,
    BottomEntry
  }
})
export default class Index extends Vue {

}
